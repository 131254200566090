export class Bag extends Map {
    constructor(object = {}) {
        super()
        this.from(object)
    }
    from(object) {
        this.clear()
        for (const key in object) {
            this.set(key, object[key])
        }
    }

    get(key, defaultVal = null) {
        return super.get(key) || defaultVal
    }
}

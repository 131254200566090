import axios from 'axios'
import { AUTH_STATE, useUserStore } from '@store/user'
import { useLanguageStore } from '@store/lang'
import { APIError } from './APIError'
import { useGlobalEnv } from '@store/globalEnv.js'
import { useAppStore } from '@store/app.js'
import { useGlobalSnackBar } from '@store/globalSnakbar.js'
import i18n from '@core/i18n/index.js'
import { EventEmmiter } from '@utils/EventEmmiter.js'

class HttpCallQueue {
    static _instance = null
    static getInstance() {
        if (HttpCallQueue._instance == null) {
            HttpCallQueue._instance = new HttpCallQueue()
        }
        return HttpCallQueue._instance
    }
    constructor() {
        this._queue = []
        this.running = false
    }

    /**
     *  @param {ApiRequest} request
     */
    add(request) {
        this._queue.push(request)
    }
    async run() {
        if (this.running) return
        this.running = true
        while (this._queue.length > 0) {
            const request = this._queue.shift()
            await request.execute()
        }
        this.running = false
    }
}

export default class ApiClient {
    static _instance = null

    constructor() {
        this._baseURL = import.meta.env.VITE_API_URL
        this.axios = axios.create({
            baseURL: this._baseURL,
            withCredentials: true,
        })
        this.axios.interceptors.request.use(this.requestInterceptor.bind(this))
    }

    get defaultsHeaders() {
        const userStore = useUserStore()
        const globalEnv = useGlobalEnv()
        const langStore = useLanguageStore(false)
        let headers = {}
        if (userStore.isAuth == AUTH_STATE.AUTHENTIFICATED) {
            headers.Authorization = `Bearer ${userStore.access_token}`
        }
        return {
            ...headers,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Accept-Version': import.meta.env.VITE_APP_VERSION,
            EnvMode: globalEnv.globalEnv,
            Locale: langStore.lang,
        }
    }

    static getInstance() {
        if (ApiClient._instance == null) {
            ApiClient._instance = new ApiClient()
        }
        return ApiClient._instance
    }

    static getQueryString(query = null) {
        query = { ...query } //unlink the object of potential vue reactivity
        for (let prop in query)
            if (query[prop]?.length == 0 || !Boolean(query[prop]))
                delete query[prop]

        if (!query) return ''
        let params = new URLSearchParams()
        for (let key in query) {
            if (query[key] != null || query[key] != undefined)
                params.append(key, query[key])
        }
        return params.toString()
    }

    requestInterceptor(config) {
        config.headers = this.getHeaders(config.headers)
        return config
    }

    getHeaders(headers = {}) {
        return { ...this.defaultsHeaders, ...headers }
    }

    get(path, query, ...args) {
        const queryString = ApiClient.getQueryString(query)
        path = `${path}?${queryString}`
        return new ApiRequest(this.axios.get, path, ...args)
    }

    post(path, payload, query, ...args) {
        const queryString = ApiClient.getQueryString(query)
        path = `${path}?${queryString}`
        args.unshift(payload)
        return new ApiRequest(this.axios.post, path, ...args)
    }

    put(path, payload, query, ...args) {
        const queryString = ApiClient.getQueryString(query)
        path = `${path}?${queryString}`
        args.unshift(payload)
        return new ApiRequest(this.axios.put, path, ...args)
    }

    delete(path, query, ...args) {
        const queryString = ApiClient.getQueryString(query)
        path = `${path}?${queryString}`
        return new ApiRequest(this.axios.delete, path, ...args)
    }
}

export class ApiRequest {
    constructor(axiosRequest, path, ...args) {
        this.request = axiosRequest
        this.args = [path.replace(/\/\//gi, '/'), ...args]
        this.bindedVue = null
        this.autoDisconect = true
        this.modifiers = []
        this._doNotCatch = false
        this.attempt = 0
        this.event = new EventEmmiter()
        this.showSnackBar = true
    }

    doNotRetry() {
        this.attempt = 2
        return this
    }
    doNotCatch() {
        this._doNotCatch = true
        return this
    }
    doNotShowSnackBar() {
        this.showSnackBar = false
        return this
    }

    doNotDisconnect() {
        this.autoDisconect = false
        return this
    }

    async execute() {
        if (!window.$alert)
            window.$alert = (message, opt) => console.error(message)
        this.attempt++
        try {
            if (this.bindedVue) this.bindedVue.errors = {}
            let response = await this.request(...this.args)
            response = this.applyModifier(response)
            this.event.emit('success', response)
        } catch (error) {
            let apiError = new APIError(error)
            if (this._doNotCatch) return this.event.emit('error', apiError)
            if (apiError.hasStatus(422)) {
                if (this.bindedVue) {
                    if (typeof this.bindedVue.errors !== 'undefined')
                        this.bindedVue.errors = {
                            ...apiError.errors,
                        }
                    if (typeof this.bindedVue.error !== 'undefined')
                        this.bindedVue.error = {
                            message: apiError.apiMessage,
                            errors: apiError.errors,
                        }
                }
                window.$alert(apiError.apiMessage, { color: 'error' })
                return this.event.emit('error', apiError)
            }
            if (apiError.isAuthError() && this.autoDisconect) {
                //try to get new token
                if (this.attempt < 2) {
                    const userStore = useUserStore()
                    await userStore.getNewToken()
                    return await this.execute()
                }

                const env = import.meta.env.VITE_APP_ENV
                if (env.startsWith('prod')) {
                    const userStore = useUserStore()
                    const appStore = useAppStore()
                    userStore.logout(() => {
                        appStore.goToLoginPage({
                            error: i18n.global.t(
                                'generics.errors.disconnected'
                            ),
                        })
                    })
                } else {
                    window.$alert(
                        apiError.apiMessage +
                            " </br>(<b>ATTENTION</b> Cette erreur est traitée différemment en production, elle entraîne une déconnexion et redirige l'utilisateur vers la page de connexion)",
                        { color: 'error' }
                    )
                }
                return null
            } else if (apiError.hasStatus(403) || apiError.hasStatus(401)) {
                if (this.showSnackBar) {
                    const globalSnackbar = useGlobalSnackBar()
                    globalSnackbar.showSnackBar(
                        i18n.global.t('generics.errors.unauthorized'),
                        { color: 'error' }
                    )
                }
            }

            if (apiError.hasStatus(500)) {
                const globalSnackbar = useGlobalSnackBar()
                globalSnackbar.showSnackBar(
                    i18n.global.t('generics.errors.default'),
                    { color: 'error' }
                )
            }

            //throw to the caller
            this.event.emit('error', apiError)
        }
    }

    call() {
        //add the call to the queue
        const queue = HttpCallQueue.getInstance()
        queue.add(this)
        queue.run()

        return new Promise((resolve, reject) => {
            this.event.on('success', (response) => resolve(response))
            this.event.on('error', (error) => reject(error))
        })
    }
    async callImmediately() {
        this.execute().then()
        return new Promise((resolve, reject) => {
            this.event.on('success', (response) => resolve(response))
            this.event.on('error', (error) => reject(error))
        })
    }

    bindVue(vue) {
        this.bindedVue = vue
        return this
    }

    disableAutoDisconect() {
        this.autoDisconect = false
        return this
    }
    applyModifier(response) {
        this.modifiers.forEach((modifier) => (response = modifier(response)))
        return response
    }
    addModifier(modifier) {
        this.modifiers.push(modifier)
        return this
    }
}

/**
 * UTILS FUNCTIONS FOR API CALLS
 */

/**
 * Transform an object into a query string
 * @param {*} query
 * @returns
 */
export function getQueryString(query) {
    let params = new URLSearchParams()
    for (let key in query) {
        if (query[key] != null || query[key] != undefined)
            params.append(key, query[key])
    }
    return params.toString()
}

export class EventEmmiter {
    constructor() {
        this._listeners = {}
    }

    on(name, callback) {
        if (!this._listeners[name]) {
            this._listeners[name] = []
        }
        this._listeners[name].push(callback)
    }
    emit(name, data) {
        if (this._listeners[name]) {
            this._listeners[name].forEach((callback) => {
                callback(data)
            })
        }
    }

    off(name, callback) {
        if (this._listeners[name]) {
            this._listeners[name] = this._listeners[name].filter(
                (listener) => listener !== callback
            )
        }
    }

    once(name, callback) {
        const onceCallback = (...params) => {
            callback(...params)
            this.off(name, onceCallback)
        }

        this.on(name, onceCallback)
    }

    offAll() {
        this._listeners = {}
    }

    offAllEvent(name) {
        this._listeners[name] = []
    }
}

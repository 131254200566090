import { reactive, ref } from 'vue'
import { HeadsetStream } from './HeadsetStream'
import { RTCConnection } from './RTCConnection'
import { RTCEvent } from './RaPeer'
import { Database } from '@core/database/init.js'
import { JSONParseSelectedProperties } from '@utils/JSONParseSelectedProperties.js'
import { useGlobalSnackBar } from '@store/globalSnakbar.js'
import { HeadsetImage } from './HeadsetImage.js'
import { useUserStore } from '@store/user.js'
import { LicenseCollection } from '@libs/Collections/LicenseCollection.js'
import { SyncedHeadsets } from '@core/database/tables/SyncedHeadsets.js'
import { HeadsetLocalRenaming } from '@core/database/tables/HeadsetLocalRenaming.js'
import { HeadsetProvider } from '@providers/HeadsetProvider.js'
import { useAppStore } from '@store/app.js'
import { EventEmmiter } from '@utils/EventEmmiter.js'

export const HEADSET_ACTIONS = {
    PAUSE: 'pause',
    RESUME: 'resume',
    SOUND_MUTE: 'sound_mute',
    SOUND_UNMUTE: 'sound_unmute',
    MICRO_MUTE: 'micro_mute',
    MICRO_UNMUTE: 'micro_unmute',
    SCREEN_RESOLUTION: 'screen_resolution',
    START_STREAM: 'start_stream',
    STOP_STREAM: 'stop_stream',
}

export class Headset extends EventEmmiter {
    static mdmStore = null /** @set in @store/mdm.js */
    static instances = new Map()

    constructor(serialNumber, config = null) {
        super()
        /**
         * The serial number of the headset
         * @type {String}
         */
        this.serialNumber = serialNumber

        /**
         * @type {RTCConnection}
         */
        this.receivingRTCConnection = new RTCConnection(
            {
                initiator: false,
                trickle: false,
            },
            reactive(this)
        )
        /**
         * @type {RTCConnection}
         */
        this.emitingRTCConnection = new RTCConnection(
            {
                initiator: true,
                trickle: false,
            },
            reactive(this)
        )

        /**
         * Local renaming => feature that need to be removed when the renaming v2 will be implemented
         * @type {null|String}
         */
        this.localRenaming = ref('')

        /**
         * Contains api and state information about the headset
         * @type {{name: string, battery: null}}
         */
        this._protect_state = false
        this.state = {}
        this.setState(
            {
                ...config.state,
            } || {
                battery: null,
                name: 'Unknown',
            }
        )

        /**
         * Determine if a formation is currently running on this headset, and the modules informations
         * @type {null|Object}
         */
        this.formationState = reactive({})

        /**
         * The stream instance of the headset
         * @type {HeadsetStream}
         */
        let self = reactive(this)
        this.stream = HeadsetStream.getInstance(self)
        this.stream.headset = self

        /**
         * Define the headset instance in the instances map
         */
        Headset.instances.set(serialNumber, self)
        return self
    }

    /**
     * Get the last user connected to this headset
     * @returns {Object}
     */
    get user() {
        return (
            this.state?.last_session?.user || {
                identity: 'Unknown',
            }
        )
    }

    get id() {
        return this.state?.id || undefined
    }

    /**
     * Get the websocket connection of the headset, the online_ws has the priority
     * @returns {null|WebSocketClient
     */
    get _ws() {
        /**
         * @type {WebSocketClient}
         */
        let onlineWS = Headset.mdmStore.online_ws
        /**
         * @type {WebSocketClient}
         */
        let localWS = Headset.mdmStore.local_ws

        if (localWS && localWS.headsetManager.has(this.serialNumber))
            return localWS
        if (onlineWS && onlineWS.headsetManager.has(this.serialNumber))
            return onlineWS

        return null
    }

    get name() {
        return (
            this._localRenaming ||
            this?.state?.custom_name ||
            this.state?.name ||
            'Unknown'
        )
    }

    set name(value) {
        this.state.custom_name = value
    }

    get _localRenaming() {
        return typeof this.localRenaming === 'string'
            ? this.localRenaming
            : this.localRenaming.value
    }

    set _localRenaming(value) {
        if (typeof this.localRenaming === 'string') {
            this.localRenaming = value
        } else {
            this.localRenaming.value = value
        }
    }

    get image() {
        return HeadsetImage.getImage(this.state.model)
    }
    /**
     * Get the status of the headset (connected to websocket == connected and ready to RTC)
     * @returns {boolean}
     */
    get isConnected() {
        return Boolean(this._ws)
    }

    /**
     * Determine if the headset is an online or a local headset
     * @returns {*|boolean}
     * @private
     */
    get _isLAN() {
        return this._ws?.isLAN || false
    }
    /**
     * Determine if the headset has an opened RTCConnection
     * @returns {boolean}
     */
    get hasRTCConnection() {
        return (
            Boolean(!this.emitingRTCConnection.isClosed()) ||
            Boolean(!this.receivingRTCConnection.isClosed())
        )
    }

    get hasWSSocket() {
        return Boolean(this._ws)
    }

    /**
     * Determine if the headset is drivable
     * A headset is drivable if it has an opened RTCConnection, not in idle state, not in stream on another device, and connected to the websocket
     * @returns {boolean|*|boolean}
     */
    get isDrivable() {
        if (this.hasRTCOpen) return true
        if (this.isIdle) return false
        return (this.isConnected && this.state?.streamable) || false
    }

    /**
     * Does the headset has an opened RTCConnection
     * @returns {boolean}
     */
    get hasRTCOpen() {
        return (
            this.emitingRTCConnection?.active ||
            this.receivingRTCConnection?.active
        )
    }

    /**
     * Determine if the headset is in streaming state, use in view
     * @returns {boolean}
     */
    get isStreaming() {
        return this.isConnected && !this.state?.streamable
    }

    /**
     * Determine if the headset is in idle state, use in view
     * @returns {false|*|boolean}
     */
    get isIdle() {
        /**
         * state.idle can be not defined on legacy headsets versions
         * @type {*|boolean}
         */
        const idleValue = this.state.hasOwnProperty('idle')
            ? this.state.idle
            : false
        return this.isConnected && idleValue
    }

    /**
     * get the emiting peer use to send instruction throug RTC dataChannel
     * @returns {RTCConnection}
     * */
    get emitingPeer() {
        return this.emitingRTCConnection
    }

    /**
     * @returns {RTCConnection}
     * */
    get receivingPeer() {
        return this.receivingRTCConnection
    }

    /**
     * Get the state chips of the headset, use in views
     * @returns {*[]}
     */
    get statesChips() {
        let chips = []
        if (this.isStreaming && !this.hasRTCOpen)
            chips.push('headsets.pilotage.in_streaming')
        if (this.isIdle) chips.push('headsets.pilotage.idle')
        if (this.isInFormation && !this.hasRTCOpen)
            chips.push('headsets.pilotage.in_formation')
        return chips
    }

    /**
     * get the priority state chip, use in views
     * @returns {String}
     */
    get stateChip() {
        return this.statesChips[0]
    }

    get isInFormation() {
        return Object.keys(this.formationState).length > 0
    }

    setStateProtection(val) {
        this._protect_state = val
        return this._protect_state
    }

    static getInstance(serialNumber, ...args) {
        if (this.instances.has(serialNumber))
            return this.instances.get(serialNumber)
        return new Headset(serialNumber, ...args)
    }

    /**
     * Set up the RTCConnections to the headset (emiting and receiving)
     * @returns {Promise<Awaited<void>[]>}
     */
    async initRTCConnection() {
        function initEmiting() {
            return new Promise((resolve, reject) => {
                try {
                    if (this.emitingRTCConnection.isClosed()) {
                        this.emitingRTCConnection.connect()
                        this.emitingRTCConnection.once(RTCEvent.READY, resolve)
                    } else resolve()
                } catch (error) {
                    console.error(error)
                    reject(error)
                }
            })
        }

        function initReceiving() {
            return new Promise((resolve, reject) => {
                try {
                    if (this.receivingRTCConnection.isClosed()) {
                        this.receivingRTCConnection.connect()
                        this.receivingRTCConnection.on(
                            RTCEvent.STREAM,
                            this.stream.handle.bind(this.stream)
                        )
                        //on n'attend pas le ready state puisque nous n'utilisons pas cette peer pour envoyer des données
                        resolve()
                    } else resolve()
                } catch (error) {
                    reject(error)
                }
            })
        }

        return await Promise.all([
            initEmiting.call(this),
            initReceiving.call(this),
        ])
    }

    /**
     * Close the RTCConnections to the headset (emiting and receiving)
     *
     */
    closeRTCConnection() {
        if (this.emitingRTCConnection) {
            this.emitingRTCConnection?.destroy()
        }

        if (this.receivingRTCConnection) {
            this.receivingRTCConnection.destroy()
        }
    }

    /**
     * Call when an RaPeer connection cannot be established, and we want to abort the stream and close the stream
     * @returns {Promise<void>}
     */
    async abortStream() {
        const globalSnackBar = useGlobalSnackBar()
        globalSnackBar.showSnackBar('headsets.pilotage.errors.aborted', {
            closable: false,
            class: ['default-alert', 'right-alert'],
        })
        await this.stream.close()
    }

    /**
     * Destroy the headset (call if the headset is disconnected or if we want to disconnect it)
     * @returns {void}
     */
    destroy() {
        this.closeRTCConnection() //close before, that make sure that the stream wont try send data to a closed connection
        if (this.stream) this.stream.destroy()
    }

    /**
     * Call when we want to save setting to the headset
     */
    saveState() {
        this._ws.send(
            JSON.stringify({
                type: 'saveState',
                state: {
                    settings: this.state.settings,
                    name: this.state.name,
                    custom_name: this.state.custom_name,
                    custom_name_updated_at: this.state.custom_name_updated_at,
                },
                serialNumber: this.serialNumber,
            })
        )
    }

    /**
     * Send a given payload to the headset through the websocket connection
     * @param data
     * @constructor
     */
    WSsend(data) {
        this._ws.send(
            JSON.stringify({
                ...data,
                serialNumber: this.serialNumber,
            })
        )
    }

    /**
     * Send the given payload to the headset throug the emiting RTCConnection
     * @param payload
     * @returns {Promise<void>}
     */
    async send(payload) {
        if (
            this.receivingRTCConnection.isClosed() ||
            this.emitingRTCConnection.isClosed()
        )
            await this.initRTCConnection()
        try {
            if (typeof payload === 'object') payload = JSON.stringify(payload)
            this.emitingRTCConnection.send(payload)
        } catch (error) {
            console.error(error)
            console.trace('ERROR SENDING PAYLOAD', payload)
        }
    }

    /**
     * Set the state of the formation, you need to use this method to set the state of the formation
     * @param state
     */
    setFormationState(state) {
        if (state?.modules_playlist && state?.modules_playlist.length == 0)
            return
        if (!state) {
            this.stream?.setState({ pause: false }) //if the formation is stopped, we unpause to be sure that after the formation, the stream will be ready
            for (let prop in this.formationState)
                delete this.formationState[prop]
        }

        this.formationState = { ...this.formationState, ...state }
    }

    /**
     * Set the state of the headset, you need to use this method to set the state of the headset
     * Usefull to format the state before set it, and save it locally in tablet mode
     *
     * @param state
     * @param silent
     */
    setState(state, silent = false) {
        if (!silent) this.emit('state', state)
        if (this._protect_state) return
        if (state.formationsReadyToStart) {
            let formationsIds = null

            if (this.constructor.mdmStore.companyLicenses) {
                formationsIds = new LicenseCollection(
                    this.constructor.mdmStore.companyLicenses
                ).formations.map((f) => f.id)
            }

            state.formationsReadyToStart = state.formationsReadyToStart
                .filter(
                    (formation) =>
                        formation?.content_type === undefined ||
                        (['rab', 'video360'].includes(formation.content_type) &&
                            (formationsIds
                                ? formationsIds.includes(formation.id)
                                : true))
                )
                .map((formation) =>
                    JSONParseSelectedProperties(formation, [
                        'name',
                        'description',
                        'modules.name',
                        'modules.description',
                        'pack.name',
                        'objectives',
                    ])
                )
        }

        if(state.formationState){
            this.setFormationState(state.formationState)
        }else{
            this.setFormationState(null) //reset formation state
        }

        let hasRTCOpen = this.emitingPeer?.active || this.receivingPeer?.active
        let idle = state.idle || false
        let needUndrive =
            !hasRTCOpen && (state.streamable != this.state.streamable || idle)

        if (needUndrive) {
            this.state = { ...this.state, ...state }
            this.constructor.mdmStore.setHeadsetsAppDriven()
        } else {
            this.state = { ...this.state, ...state }
        }
        this.saveStateLocally()
        this.loadLocalRenaming()
    }

    /**
     * Save the state of the headset in the local database
     */
    async saveStateLocally() {
        let db = Database.getInstance()
        let exists = await db
            .tables(SyncedHeadsets.storeName)
            .findOne({ serialNumber: this.serialNumber })

        if (exists) {
            let stateToSave = { ...JSON.parse(exists.state), ...this.state }
            await exists.update({ state: JSON.stringify(stateToSave) })
        }
    }

    async loadLocalRenaming() {
        const db = Database.getInstance()
        const headsetLocalRenaming = await db
            .tables(HeadsetLocalRenaming.storeName)
            .findOne({ serialNumber: this.serialNumber })
        setTimeout(async () => {
            if (headsetLocalRenaming) {
                let localRenamingCreatedAt = new Date(
                    headsetLocalRenaming.created_at
                )
                localRenamingCreatedAt.setSeconds(0, 0)

                const appStore = useAppStore()
                const userStore = useUserStore()
                let apiUpdatedAt =
                    this.state.custom_name_updated_at == undefined ||
                    appStore.isOffline
                        ? 0
                        : new Date(this.state?.custom_name_updated_at)
                if (apiUpdatedAt) apiUpdatedAt.setHours(apiUpdatedAt.getHours() + 2,apiUpdatedAt.getMinutes(),0,0)
                if (localRenamingCreatedAt > apiUpdatedAt) {
                    this._localRenaming = headsetLocalRenaming.name
                    if (headsetLocalRenaming.name !== this.state.custom_name &&
                      this.isSameUser(userStore.user)
                    ){

                        await this.updateTheApiName(headsetLocalRenaming.name)
                    }
                } else {
                    if (appStore.isOffline) {
                        this._localRenaming = headsetLocalRenaming.name
                    } else
                        await this.setLocalRenaming(
                            this.state.custom_name,
                            apiUpdatedAt
                        )
                }
            }
        }, 400)
    }
    async setLocalRenaming(name, created_at = new Date()) {
        const db = Database.getInstance()
        let exists = await db
            .tables(HeadsetLocalRenaming.storeName)
            .findOne({ serialNumber: this.serialNumber })
        created_at = created_at.toISOString()
        if (exists) {
            await exists.update({ name, created_at })
        } else {
            await db.tables(HeadsetLocalRenaming.storeName).create({
                serialNumber: this.serialNumber,
                name,
                created_at,
            })
        }
        this._localRenaming = name
        await this.saveStateLocally()
    }

    updateTheApiName(name) {
        this.state.custom_name = name
        return HeadsetProvider.update(this.user.company.id, this.id, {
            pretty_name: name,
        }).call()
    }
    hasLocalRenaming() {
        return Boolean(this.localRenaming)
    }

    /**
     * Send action to the headset to go back to the hub
     */
    goToHub() {
        this.WSsend({ type: 'goBackToHub' })
    }

    /**
     * Check if the user linked to the headset is the same as the given user
     * Warning : when the headset is in LAN mode, the user in the headset is not defined
     * @param user
     * @returns {boolean}
     */
    isSameUser(user) {
        return this.user?.id === user?.id
    }
}

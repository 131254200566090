<script>
    import { mapActions, mapState } from 'pinia'
    import { mixins } from '@realityteam/reality-academy-ui-kit'

    import ApplicationLayout from '@layouts/global/ApplicationLayout.vue'
    import HeadsetsList from '@components/Headsets/HeadsetsList.vue'
    import BatteryLevel from '@components/Headsets/BatteryLevel.vue'
    import ConnectedStatus from '@components/Headsets/ConnectedStatus.vue'
    import HeadsetSelection from '@components/Headsets/HeadsetSelection.vue'
    import HeadsetSettings from '@components/Headsets/HeadsetSettings.vue'
    import SearchHeadsetDialog from '@components/Headsets/SearchHeadsetDialog.vue'
    import HeadsetsTableList from '@components/Headsets/HeadsetsTableList.vue'

    import { useMDMStore } from '@store/mdm.js'

    import CompanyOrMineMixin from '@mixins/CompanyOrMineMixin.js'
    import UserOrMeMixin from '@mixins/UserOrMeMixin.js'
    import GlobalLanguageSelector from '@components/global/Languages/GlobalLanguageSelector.vue'
    import PilotageSettings from '@components/Headsets/PilotageSettings.vue'
    import GroupedHeadsetSettings from '@components/Headsets/GroupedHeadsetSettings.vue'
    import FaqHeadset from '@components/Headsets/FAQ/FaqHeadset.vue'
    import { useFAQStore } from '@store/faqTablet.js'
    import HeadsetCompanyCounter from '@components/Headsets/HeadsetCompanyCounter.vue'

    export default {
        components: {
            HeadsetCompanyCounter,
            FaqHeadset,
            GroupedHeadsetSettings,
            PilotageSettings,
            GlobalLanguageSelector,
            HeadsetsTableList,
            BatteryLevel,
            ConnectedStatus,
            HeadsetSelection,
            ApplicationLayout,
            SearchHeadsetDialog,
            HeadsetsList,
            HeadsetSettings,
        },

        mixins: [mixins.RaDialogsMixin, CompanyOrMineMixin, UserOrMeMixin],
        computed: {
            ...mapState(useMDMStore, [
                'syncedHeadsets',
                'notSyncedHeadsets',
                'headsetsAppDriven',
                'companyHeadsets',
                'MDM_LOADING',
                'companyDevices',
            ]),
            ...mapState(useFAQStore, ['faqIsOpen']),
            notSyncedConnectedHeadsets() {
                return this.notSyncedHeadsets.filter((h) => h.isConnected)
            },
        },

        data() {
            return {
                headsetToParam: [],
            }
        },
        watch: {
            companyID() {
                if (this.companyID) this.fetchCompanyHeadsets(this.companyID)
            },
        },
        created() {
            this.startMDM()
            this.setCompanyID(this.companyID)
        },
        methods: {
            ...mapActions(useMDMStore, [
                'setCompanyID',
                'driveHeadset',
                'unDriveHeadset',
                'fetchCompanyHeadsets',
                'handleStartFormation',
                'handleStream',
                'startMDM',
            ]),

            /**
             * Call when the search LAN headset is validated
             * @env tablet only
             * @param {Headset[]} headsets
             */
            handleSync(headsets) {
                let mdmStore = useMDMStore()
                for (let headset of headsets) mdmStore.syncAnHeadset(headset)
            },
            /**
             * handle click on table row
             * @env web only
             * @param {item:Headset} props
             */
            handleClickHeadset(event, { item }) {
                this.$router.push(
                    {
                        name: this.$route.name.replace(/index$/, 'show'),
                        params: {
                            serialNumber: item.serialNumber,
                        },
                    },
                    this.$route
                )
            },
            /**
             * handle change filter on the table
             * @env web only
             * @param filter
             */
            handleFilters(filter) {
                this.fetchCompanyHeadsets(this.companyID, filter, false, true)
            },

            toggleDriveHeadset(headset) {
                if (
                    this.headsetsAppDriven.some(
                        (h) => h.serialNumber == headset.serialNumber
                    )
                ) {
                    this.unDriveHeadset(headset)
                } else {
                    this.driveHeadset(headset)
                }
            },

            handleDriveHeadset(event, headset) {
                if (!headset.isDrivable)
                    return (this.headsetToParam = [headset])
                this.headsetToParam = []
                this.toggleDriveHeadset(headset)
            },
        },
    }
</script>
<template>
    <ApplicationLayout>
        <template #tabletAside>
            <ra-stack
                distribution="equal-spacing"
                gap="8"
                refresh
                @load="handleFilters"
            >
                <ra-spacer height="0" />
                <ra-box padding=",6">
                    <ra-stack
                        alignement="center"
                        direction="horizontal"
                        gap="5"
                    >
                        <b class="chip bg-primary">1</b>
                        <div gap="0">
                            <ra-typo variant="title">
                                {{
                                    $t(
                                        'headsets.pilotage.steps.step0.title.content'
                                    )
                                }}
                                <ra-typo
                                    bold
                                    variant="title"
                                >
                                    {{
                                        $t(
                                            'headsets.pilotage.steps.step0.title.content_huge'
                                        )
                                    }}
                                </ra-typo>
                            </ra-typo>
                        </div>
                    </ra-stack>
                </ra-box>
                <ra-box
                    height="60vh"
                    scroll-y
                >
                    <HeadsetsList
                        :columns="[
                            {
                                name: 'state',
                            },
                            {
                                name: 'add',
                                width: '30px',
                            },
                            {
                                name: 'settings',
                                width: '100px',
                            },
                        ]"
                        :headsets="syncedHeadsets"
                        :no-headset="'Aucun casque synchronisé'"
                        @click:item="handleDriveHeadset"
                    >
                        <template v-slot:state="{ headset }">
                            <ra-stack
                                direction="horizontal"
                                gap="4"
                            >
                                <img
                                    :src="headset.image"
                                    alt=""
                                    class="object-fit-contain"
                                />
                                <ra-stack gap="2">
                                    <ra-stack
                                        alignement="center"
                                        direction="horizontal"
                                    >
                                        <ra-typo
                                            bold
                                            maxWidth="150px"
                                            no-word-wrap
                                            variant="subtitle"
                                        >
                                            {{ headset.name }}
                                        </ra-typo>
                                        <v-chip
                                            v-if="headset.stateChip"
                                            size="x-small"
                                        >
                                            {{ $t(headset.stateChip) }}
                                        </v-chip>
                                    </ra-stack>
                                    <ra-stack
                                        alignement="center"
                                        direction="horizontal"
                                        gap="6"
                                    >
                                        <connected-status
                                            :isLAN="headset._isLAN"
                                            :value="headset.isConnected"
                                        />
                                        <battery-level
                                            v-if="headset.isConnected"
                                            :value="headset.state.batteryLevel"
                                        />
                                    </ra-stack>
                                </ra-stack>
                            </ra-stack>
                        </template>
                        <template v-slot:settings="props">
                            <v-btn
                                :color="
                                    props.isSelected
                                        ? 'primary'
                                        : 'primary-lighten-1'
                                "
                                density="comfortable"
                                icon="mdi-cog"
                                variant="plain"
                                @click.prevent.stop="
                                    () => {
                                        headsetToParam = [props.headset]
                                    }
                                "
                            />
                        </template>
                        <template v-slot:add="props">
                            <v-btn
                                v-if="props.headset.isDrivable"
                                :icon="
                                    headsetsAppDriven.some(
                                        (h) =>
                                            h.serialNumber ==
                                            props.headset.serialNumber
                                    )
                                        ? 'mdi-check'
                                        : 'mdi-plus'
                                "
                                color="primary-lighten-1"
                                density="comfortable"
                                variant="plain"
                            />
                        </template>
                    </HeadsetsList>
                </ra-box>
                <ra-stack class="overflow-visible">
                    <ra-button
                        bg="surface-darken-1"
                        class="notify-pastille-container"
                        flat
                        margin=",6"
                        prepend-icon="mdi-lan"
                        semi
                        @click="openDialog('dialog-search-headset')"
                    >
                        {{
                            $t(
                                'headsets.pilotage.steps.step0.add_headset.title'
                            )
                        }}
                        <div
                            v-if="notSyncedConnectedHeadsets.length"
                            class="notify-pastille"
                        >
                            {{ notSyncedConnectedHeadsets.length }}
                        </div>
                    </ra-button>
                </ra-stack>
            </ra-stack>
        </template>

        <web>
            <ra-box>
                <ra-heading-title
                    :subtitle="$tc('generics.management_of')"
                    :title="$tc('headsets._name', 2)"
                >
                    <template v-slot:actions>
                        <slot name="actions"></slot>
                        <headset-company-counter
                            v-if="user.admin"
                            :count="companyDevices.count"
                            :max="companyDevices.max"
                            density="comfortable"
                        />
                    </template>
                </ra-heading-title>
                <headsets-table-list
                    :company="company"
                    :items="syncedHeadsets"
                    :loading="MDM_LOADING && MDM_LOADING != -1"
                    :scoped-former="user.admin ? null : user"
                    @startFormation="handleStartFormation"
                    @stream="handleStream"
                    @update:filters="handleFilters"
                    @click:row="handleClickHeadset"
                />
            </ra-box>
        </web>

        <tablet>
            <template v-if="!faqIsOpen">
                <HeadsetSelection
                    v-if="!headsetToParam.length"
                    :headsets="headsetsAppDriven"
                    @openSettings="
                        () =>
                            headsetsAppDriven.forEach((h) =>
                                headsetToParam.push(h)
                            )
                    "
                    @unDrive="unDriveHeadset"
                />
                <HeadsetSettings
                    v-else-if="headsetToParam.length == 1"
                    v-model="headsetToParam[0]"
                    @close="
                        () => {
                            headsetToParam.splice(0, headsetToParam.length)
                        }
                    "
                />
                <grouped-headset-settings
                    v-else
                    :headsetsToParam="headsetToParam"
                    @close="headsetToParam = []"
                />
            </template>
            <template v-else><faq-headset /></template>
        </tablet>
        <ra-dialog ref="dialog-search-headset">
            <SearchHeadsetDialog
                :headsets="notSyncedHeadsets"
                @sync="handleSync"
            />
        </ra-dialog>
    </ApplicationLayout>
</template>
